import { useStore } from '@nanostores/react'
import { $isLoggedIn } from '@/stores/account/computed'
import CustomerPoints from './CustomerPoints/CustomerPoints'
import CustomerSubscriptions from './CustomerSubscriptions/CustomerSubscriptions'
import CustomerWelcome from './CustomerWelcome/CustomerWelcome'
import RecentPurchases from './RecentPurchases/RecentPurchases'
import './LoggedInHero.scss'

const LoggedInHero = () => {
  const isLoggedIn = useStore($isLoggedIn)

  if (!isLoggedIn) return null

  return (
    <div className="c-logged-in-hero">
      <div className="c-logged-in-hero__wrapper">
        <div className="c-logged-in-hero__header">
          <CustomerWelcome />
          <CustomerPoints />
        </div>
        <div className="c-logged-in-hero__content">
          <CustomerSubscriptions />
          <RecentPurchases />
        </div>
      </div>
    </div>
  )
}

export default LoggedInHero
