import { useStore } from '@nanostores/react'
import './CustomerWelcome.scss'
import { $customer } from '@/stores/account/stores'

const CustomerWelcome = () => {
  const customer = useStore($customer)

  const firstName = customer?.firstName

  return (
    <div className="c-customer-welcome">
      <h1 className="c-customer-welcome__text type-h3">
        {firstName ? (
          <span className="c-customer-welcome__name">
            Welcome, <span data-cs-mask>{firstName}</span>!
          </span>
        ) : (
          <span>Welcome Back!</span>
        )}
      </h1>
    </div>
  )
}

export default CustomerWelcome
