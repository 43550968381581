import { useEffect, useState } from 'react'
import OrderLineItem from './OrderLineItem/OrderLineItem'
import { Pagination } from 'swiper/modules'
import { getCustomerOrders } from '@/clients/shopify/client'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/scss'
import 'swiper/scss/pagination'
import './RecentPurchases.scss'
import { variantIsSubscription } from '@/utilities/commerce'

const ORDERS_PER_PAGE = 200
const DISPLAYED_PER_PAGE = 3

const RecentPurchases = () => {
  const [orders, setOrders] = useState<any[]>([])
  const [displayedOrders, setDisplayedOrders] = useState<any[]>([])

  const fetchOrders = async () => {
    try {
      const result = await getCustomerOrders(ORDERS_PER_PAGE)

      let formattedOrders = result.edges
        .flatMap((order: any) =>
          order.node.lineItems.edges.map((edge: any) => ({
            ...edge.node,
            statusUrl: order.node.statusUrl,
          })),
        )
        .filter((lineItem) => {
          // If item is not a subscription, not an add-on, and has a price greater than 0
          if (
            !variantIsSubscription(lineItem?.variant?.sku) &&
            !lineItem?.variant?.sku.includes('ADDON') &&
            lineItem?.variant?.price.amount > 0
          ) {
            return true
          }

          return false
        })

      setOrders(formattedOrders)
      setDisplayedOrders(formattedOrders.slice(0, DISPLAYED_PER_PAGE))
    } catch (error) {
      console.error('Error fetching orders', error)
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  if (!orders.length) return null

  return (
    <div className="c-recent-purchases">
      <h2 className="c-recent-purchases__header type-label">
        Recent Purchases
      </h2>
      <div className="c-recent-purchases__list">
        <div className="c-recent-purchases__list-wrapper">
          {displayedOrders.map((orderLineItem, index) => {
            return (
              <div key={index} className="c-recent-purchases__item">
                <OrderLineItem orderLineItem={orderLineItem} />
              </div>
            )
          })}
        </div>
        {orders.length > DISPLAYED_PER_PAGE && (
          <a
            href="/account?tab_id=order-history"
            className="c-recent-purchases__load button button--primary o-loadable o-loadable--small o-loadable--inverted"
          >
            Order history
          </a>
        )}
      </div>
      <Swiper
        className="swiper-container"
        modules={[Pagination]}
        spaceBetween={32}
        slidesPerView={1}
        pagination={{
          el: '#c-recent-purchases__swiper-pagination',
          clickable: true,
        }}
      >
        {displayedOrders.map((orderLineItem, index) => {
          return (
            <SwiperSlide key={index} className="swiper-slide">
              <OrderLineItem orderLineItem={orderLineItem} />
            </SwiperSlide>
          )
        })}
        {orders.length > DISPLAYED_PER_PAGE && (
          <SwiperSlide
            key="order-history"
            className="swiper-slide swiper-slide-end"
          >
            <a
              href={'/account?tab_id=order-history'}
              className="c-customer-subscriptions__load-more button button--primary o-loadable o-loadable--small o-loadable--inverted"
            >
              Order history
            </a>
          </SwiperSlide>
        )}
      </Swiper>
      <div
        id="c-recent-purchases__swiper-pagination"
        className="swiper-pagination"
      />
    </div>
  )
}

export default RecentPurchases
