import type React from 'react'
import './OrderLineItem.scss'
import {
  isItemBridalProgram,
  isItemCleanse,
  isItemSignatureMealProgramTrial,
  isItemSystemProgram,
  isMealProgramDelivered,
  itemProperties,
} from './OrderLineItem.helpers'
import {
  formatMeals,
  getMealsArrayFromSku,
  isMealProgramLineItem,
} from '@/utilities/sakara'
import dayjs from '@/utilities/dayjs-config'

const ProductImage = ({ orderLineItem }: any) => {
  let src = orderLineItem.variant?.image?.url

  if (isItemCleanse(orderLineItem)) {
    src =
      'https://cdn.shopify.com/s/files/1/0192/0076/files/Detox_Meal_Program_Updated.png?v=1652368815'
  } else if (isItemSignatureMealProgramTrial(orderLineItem)) {
    src =
      'https://cdn.shopify.com/s/files/1/0192/0076/files/Signature_Meal_Program.png?v=1652273822'
  } else if (isItemBridalProgram(orderLineItem)) {
    src =
      'https://cdn.shopify.com/s/files/1/0192/0076/files/Bridal_Meal_Program.png?v=1652273545'
  }

  return (
    <img
      className="c-order-line-item__product-image"
      alt="Product image"
      src={src}
      loading="lazy"
    />
  )
}

const OrderLineItemLink = ({ orderLineItem }: any) => {
  const slug = orderLineItem?.variant?.product?.handle

  if (isItemCleanse(orderLineItem)) {
    return (
      <a
        href="/nutrition/detox"
        className="c-order-line-item__product-link type-h5"
      >
        {orderLineItem.title}
      </a>
    )
  }

  if (isItemBridalProgram(orderLineItem)) {
    return (
      <a
        href="/nutrition/the-bridal-program"
        className="c-order-line-item__product-link type-h5"
      >
        {orderLineItem.title}
      </a>
    )
  }

  if (isMealProgramLineItem(orderLineItem)) {
    if (isItemSystemProgram(orderLineItem)) {
      return (
        <a
          href={`/nutrition/${slug}`}
          className="c-order-line-item__product-link type-h5"
        >
          {orderLineItem.title}
        </a>
      )
    }

    return (
      <a
        href="/nutrition/signature-omd"
        className="c-order-line-item__product-link type-h5"
      >
        {orderLineItem.title}
      </a>
    )
  }

  const variantId = orderLineItem?.variant.id

  return (
    <a
      href={`/products/${slug}?variant=${variantId}`}
      className="c-order-line-item__product-link type-h5"
    >
      {orderLineItem.title}
    </a>
  )
}

const OrderLineItemMeals = ({ orderLineItem }: any) => {
  const mealProgramMeals = isMealProgramLineItem(orderLineItem)
    ? getMealsArrayFromSku(orderLineItem.variant.sku)
    : null

  const mealProgramDaysOfFood = isMealProgramLineItem(orderLineItem)
    ? itemProperties(orderLineItem)._days_of_food
    : null

  if (!isMealProgramLineItem(orderLineItem)) {
    return null
  }

  return (
    <div className="c-order-line-item__meals">
      {formatMeals(mealProgramMeals, ' + ')} for {mealProgramDaysOfFood}{' '}
      {mealProgramDaysOfFood > 1 ? 'Days' : 'Day'}
    </div>
  )
}

const OrderLineItemDate = ({ orderLineItem }: any) => {
  const mealProgramStartDate = dayjs(
    itemProperties(orderLineItem)._start_date,
  ).format('MMMM D, YYYY')

  let content

  if (isMealProgramLineItem(orderLineItem)) {
    if (isMealProgramDelivered(orderLineItem)) {
      content = <span>Delivered: {mealProgramStartDate}</span>
    } else {
      content = <span>Starting: {mealProgramStartDate}</span>
    }
  } else {
    content = <span>Estimated Delivery: 'N/A'</span>
  }

  return <div className="c-order-line-item__date type-label">{content}</div>
}

const OrderLineItemCTA = ({ orderLineItem }: any) => {
  const orderStatusUrl = orderLineItem.statusUrl
  let text = 'View Order'

  return (
    <a
      href={orderStatusUrl}
      target="_blank"
      className="c-order-line-item__cta-link c-order-line-item__cta button button--secondary button--small"
    >
      {text}
    </a>
  )
}

const OrderLineItem: React.FC<any> = ({ orderLineItem }) => {
  return (
    <div className="c-order-line-item">
      <div className="c-order-line-item__purchase-details">
        <ProductImage orderLineItem={orderLineItem} />
        <div className="c-order-line-item__info">
          <h3 className="c-order-line-item__product-title">
            <OrderLineItemLink orderLineItem={orderLineItem} />
          </h3>
          <OrderLineItemMeals orderLineItem={orderLineItem} />
          <OrderLineItemDate orderLineItem={orderLineItem} />
        </div>
        <div className="c-order-line-item__cta-wrapper">
          <OrderLineItemCTA orderLineItem={orderLineItem} />
        </div>
      </div>
    </div>
  )
}

export default OrderLineItem
