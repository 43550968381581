/** @constant {RegExp} IMAGE_SIZE_POSITION - Find character before file extension */
const IMAGE_SIZE_POSITION = /(?=\.(?:jpg|jpeg|gif|png|webp)(?:\?v=\d+)?$)/i

/**
 * Remove protocol from URL path
 *
 * @param {string} path - URL path
 * @returns {string} URL path without protocol
 */
const removeProtocol = (path: any) => path.replace(/http(s)?:/, '')

/**
 * Adds a Shopify size attribute to a URL
 *
 * @param {string} src - Image source
 * @param {string} size - Image sizes
 * @returns {string} Resized image source
 */
export default (src: any, size = '') => {
  if (!size || size === 'master') {
    return removeProtocol(src)
  }

  return removeProtocol(src.replace(IMAGE_SIZE_POSITION, `_${size}`))
}
