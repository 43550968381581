import mealProgramTrialConfig from '@/config/meal-program-trial-config'
import { SYSTEM_PROGRAM_SKU_SUFFIX } from '@/constants/commerce'
import dayjs from '@/utilities/dayjs-config'
import { isBridalLineItem, isCleanseLineItem } from '@/utilities/sakara'

export const isItemCleanse = (orderLineItem: any) => {
  return isCleanseLineItem(orderLineItem.variant.sku)
}

export const isItemBridalProgram = (orderLineItem: any) => {
  return isBridalLineItem(orderLineItem.variant.sku)
}

export const isItemSystemProgram = (orderLineItem: any) => {
  return orderLineItem.variant.sku.includes(SYSTEM_PROGRAM_SKU_SUFFIX)
}

export const isItemSignatureMealProgramTrial = (orderLineItem: any) => {
  const config = mealProgramTrialConfig.find(
    (c) => c.productHandle === orderLineItem.variant.product.handle,
  )

  return config && config.programName === 'Signature'
}

export const itemProperties = (orderLineItem: any) => {
  const { customAttributes } = orderLineItem
  const propertyObject = customAttributes.reduce((obj: any, item: any) => {
    obj[item.key] = item.value
    return obj
  }, {})

  return propertyObject
}

export const isMealProgramDelivered = (orderLineItem: any) => {
  const today = dayjs()
  const startDate = dayjs(itemProperties(orderLineItem)._start_date)
  return today.isSame(startDate, 'day') || today.isAfter(startDate, 'day')
}
