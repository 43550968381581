import { useMemo } from 'react'
import SubscriptionCard from '../SubscriptionCard/SubscriptionCard'
import FailedPaymentNotice from '../FailedPaymentNotice/FailedPaymentNotice'
import { formatDate } from '@/utilities/sakara'
import dayjs from '@/utilities/dayjs-config'
import SubscriptionCardNoticeContainer from '@/components/SubscriptionCardNotice/SubscriptionCardNoticeContainer'
import './CleanBoutiqueSubscriptionCard.scss'

const CleanBoutiqueSubscriptionCard = ({ subscription }: any) => {
  const accountUrl = useMemo(() => {
    return `/account?tab_id=subscriptions&subscription_id=${subscription.id}`
  }, [subscription.id])

  const button = subscription.isCancelled ? (
    <a href={accountUrl} className="button button--secondary button--small">
      Reactivate
    </a>
  ) : (
    <a href={accountUrl} className="button button--secondary button--small">
      Manage subscription
    </a>
  )

  const cardTitle = subscription.name || subscription.productName

  const info = (
    <>
      <span className="type-body">Billed Monthly</span>
    </>
  )

  const getExtraInfo = () => {
    if (subscription.isCancelled) {
      return (
        <span className="type-label">
          Subscription cancelled{' '}
          {formatDate(subscription.endDate, 'MM/DD/YYYY')}
        </span>
      )
    }

    return (
      <>
        Next Renewal on{' '}
        <strong className="type-label">
          {dayjs(subscription.nextBillingDate).format('dddd, MMMM D')}
        </strong>
      </>
    )
  }

  const getNotice = () => {
    if (subscription.isCancelled) {
      return null
    }

    if (subscription.failedPayment) {
      return <FailedPaymentNotice subscription={subscription} />
    } else {
      return <SubscriptionCardNoticeContainer type="PRODUCT" />
    }
  }

  return (
    <div className="c-clean-boutique-subscription-card">
      <SubscriptionCard
        imageSrc={subscription.image}
        isCancelled={subscription.isCancelled}
        notice={getNotice()}
        title={cardTitle}
        info={info}
        extraInfo={getExtraInfo()}
        button={button}
      />
    </div>
  )
}

export default CleanBoutiqueSubscriptionCard
