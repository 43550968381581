import SubscriptionCardNotice from '@/components/SubscriptionCardNotice/SubscriptionCardNotice'

const FailedPaymentNotice = ({ subscription }: any) => (
  <SubscriptionCardNotice type="action-required">
    <strong>Payment Failed:</strong>
    We were unable to process your credit card.
    <a
      href={`/account?tab_id=subscriptions&subscription_id=${subscription.id}`}
    >
      Update your payment information.
    </a>
  </SubscriptionCardNotice>
)

export default FailedPaymentNotice
