import { useEffect, useState } from 'react'
import SubscriptionCardNotice from './SubscriptionCardNotice'
import type { CMSSubscriptionBannerType } from '@/types/sanity'
import { $loggedInSettings } from '@/stores/logged-in-settings/stores'
import { useStore } from '@nanostores/react'

type PropsType = {
  type: 'PRODUCT' | 'MEAL'
}

const SubscriptionCardNoticeContainer = ({ type }: PropsType) => {
  const [subscriptionBanner, setSubscriptionBanner] =
    useState<CMSSubscriptionBannerType | null>(null)
  const loggedInSettings = useStore($loggedInSettings)

  useEffect(() => {
    if (loggedInSettings?.bannerConfiguration) {
      const banner =
        type === 'PRODUCT'
          ? loggedInSettings.bannerConfiguration.productsSubscriptionBanner
          : loggedInSettings.bannerConfiguration.mealsSubscriptionBanner

      setSubscriptionBanner(banner)
    }
  }, [loggedInSettings, type])

  if (!subscriptionBanner?.bannerIsShow) return null

  return (
    <SubscriptionCardNotice theme={subscriptionBanner.bannerTheme}>
      {subscriptionBanner.bannerDescription}
      {subscriptionBanner.bannerCTAUrl && (
        <a href={subscriptionBanner.bannerCTAUrl}>
          {subscriptionBanner.bannerCTAText}
        </a>
      )}
    </SubscriptionCardNotice>
  )
}

export default SubscriptionCardNoticeContainer
