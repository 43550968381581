export default [
  {
    programName: 'Signature',
    productHandle: '2-day-organic-meal-delivery',
    numberOfWeeks: 1,
    daysOfFood: 2,
    daysOfFoodPerWeek: 2,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Signature',
    productHandle: '2-day-organic-meal-delivery-nationwide',
    numberOfWeeks: 1,
    daysOfFood: 2,
    daysOfFoodPerWeek: 2,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Signature',
    productHandle: '3-day-organic-meal-delivery',
    numberOfWeeks: 1,
    daysOfFood: 3,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Signature',
    productHandle: '3-day-organic-meal-delivery-nationwide',
    numberOfWeeks: 1,
    daysOfFood: 3,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Signature',
    productHandle: '4-day-organic-meal-delivery',
    numberOfWeeks: 1,
    daysOfFood: 4,
    daysOfFoodPerWeek: 4,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Signature',
    productHandle: '4-day-organic-meal-delivery-nationwide',
    numberOfWeeks: 1,
    daysOfFood: 4,
    daysOfFoodPerWeek: 4,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Signature',
    productHandle: '5-day-organic-meal-delivery',
    numberOfWeeks: 1,
    daysOfFood: 5,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Signature',
    productHandle: '5-day-organic-meal-delivery-nationwide',
    numberOfWeeks: 1,
    daysOfFood: 5,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Signature',
    productHandle: '7-day-organic-meal-delivery',
    numberOfWeeks: 2,
    daysOfFood: 7,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Signature',
    productHandle: '7-day-organic-meal-delivery-nationwide',
    numberOfWeeks: 2,
    daysOfFood: 7,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Signature',
    productHandle: '8-day-organic-meal-delivery',
    numberOfWeeks: 2,
    daysOfFood: 8,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Signature',
    productHandle: '8-day-organic-meal-delivery-nationwide',
    numberOfWeeks: 2,
    daysOfFood: 8,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Signature',
    productHandle: '10-day-organic-meal-delivery',
    numberOfWeeks: 2,
    daysOfFood: 10,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Signature',
    productHandle: '10-day-organic-meal-delivery-nationwide',
    numberOfWeeks: 2,
    daysOfFood: 10,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Signature',
    productHandle: '17-day-organic-meal-delivery',
    numberOfWeeks: 4,
    daysOfFood: 17,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Signature',
    productHandle: '17-day-organic-meal-delivery-nationwide',
    numberOfWeeks: 4,
    daysOfFood: 17,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Signature',
    productHandle: '18-day-organic-meal-delivery',
    numberOfWeeks: 4,
    daysOfFood: 18,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Signature',
    productHandle: '18-day-organic-meal-delivery-nationwide',
    numberOfWeeks: 4,
    daysOfFood: 18,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Signature',
    productHandle: '20-day-organic-meal-delivery-1',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Signature',
    productHandle: '20-day-organic-meal-delivery-nationwide',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Cleanse',
    productHandle: '5-day-ny-level-ii-detox-cs',
    numberOfWeeks: 1,
    daysOfFood: 5,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Cleanse',
    productHandle: '5-day-nw-level-ii-detox-cs',
    numberOfWeeks: 1,
    daysOfFood: 5,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Cleanse',
    productHandle: '5-day-la-level-ii-detox-cs',
    numberOfWeeks: 1,
    daysOfFood: 5,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Cleanse',
    productHandle: '10-day-organic-meal-delivery-level-ii-detox',
    numberOfWeeks: 2,
    daysOfFood: 10,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Cleanse',
    productHandle: '10-day-organic-meal-delivery-nationwide-level-ii-detox',
    numberOfWeeks: 2,
    daysOfFood: 10,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Brides',
    productHandle: '20-day-sakara-bride-organic-meal-delivery',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Brides',
    productHandle: '20-day-sakara-bride-organic-meal-delivery-nw',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Healthy Body Project 2021',
    productHandle: 'healthy-body-project-nationwide-12-day-2021',
    numberOfWeeks: 4,
    daysOfFood: 12,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Healthy Body Project 2021',
    productHandle: 'healthy-body-project-nationwide-19-day-2021',
    numberOfWeeks: 4,
    daysOfFood: 19,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Healthy Body Project 2021',
    productHandle: 'healthy-body-project-12-day-2021',
    numberOfWeeks: 4,
    daysOfFood: 12,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Healthy Body Project 2021',
    productHandle: 'healthy-body-project-20-day-2021',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: '30-Day Reset - 2022',
    productHandle: '30-day-reset-nationwide-12-day-2022',
    numberOfWeeks: 4,
    daysOfFood: 12,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: '30-Day Reset - 2022',
    productHandle: '30-day-reset-nationwide-20-day-2022',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: '30-Day Reset - 2022',
    productHandle: '30-day-reset-nationwide-19-day-2022',
    numberOfWeeks: 4,
    daysOfFood: 19,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: '30-Day Reset - 2022',
    productHandle: '30-day-reset-12-day-2022',
    numberOfWeeks: 4,
    daysOfFood: 12,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: '30-Day Reset - 2022',
    productHandle: '30-day-reset-20-day-2022',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'MNP - large bag',
    productHandle: '5-day-metabolism-nutrition-program',
    numberOfWeeks: 1,
    daysOfFood: 5,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'MNP - large bag',
    productHandle: '20-day-metabolism-nutrition-program',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'MNP - large bag',
    productHandle: 'nationwide-5-day-metabolism-nutrition-program',
    numberOfWeeks: 1,
    daysOfFood: 5,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'MNP - large bag',
    productHandle: 'nationwide-20-day-metabolism-nutrition-program',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: '30-Day Reset - Fall 2022',
    productHandle: 'fall-30-day-reset-20-day',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: '30-Day Reset - Fall 2022',
    productHandle: 'fall-30-day-reset-12-day',
    numberOfWeeks: 4,
    daysOfFood: 12,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: '30-Day Reset - Fall 2022',
    productHandle: 'fall-30-day-reset-nationwide-20-day',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: '30-Day Reset - Fall 2022',
    productHandle: '30-day-reset-nationwide-12-day',
    numberOfWeeks: 4,
    daysOfFood: 12,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: '30-Day Reset - Winter 2023',
    productHandle: 'jan23-the-30-day-reset-20-day',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: '30-Day Reset - Winter 2023',
    productHandle: 'jan23-the-30-day-reset-12-day',
    numberOfWeeks: 4,
    daysOfFood: 12,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: '30-Day Reset - Winter 2023',
    productHandle: 'jan23-the-30-day-reset-nationwide-20-day',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: '30-Day Reset - Winter 2023',
    productHandle: 'jan23-the-30-day-reset-nationwide-12-day',
    numberOfWeeks: 4,
    daysOfFood: 12,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Eat Pretty - 2023',
    productHandle: '2023-3-day-organic-meal-delivery-eat-pretty',
    numberOfWeeks: 1,
    daysOfFood: 3,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Eat Pretty - 2023',
    productHandle: '2023-5-day-organic-meal-delivery-eat-pretty',
    numberOfWeeks: 1,
    daysOfFood: 5,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Eat Pretty - 2023',
    productHandle: '2023-3-day-organic-meal-delivery-eat-pretty',
    numberOfWeeks: 1,
    daysOfFood: 3,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Eat Pretty - 2023',
    productHandle: '2023-5-day-organic-meal-delivery-eat-pretty',
    numberOfWeeks: 1,
    daysOfFood: 5,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Balance',
    productHandle: '4-week-campaign-12-day-program',
    numberOfWeeks: 4,
    daysOfFood: 12,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Balance',
    productHandle: '4-week-campaign-20-day-program',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Balance',
    productHandle: '4-week-campaign-11-day-program',
    numberOfWeeks: 4,
    daysOfFood: 11,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Balance',
    productHandle: '4-week-campaign-17-day-program',
    numberOfWeeks: 4,
    daysOfFood: 17,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Balance',
    productHandle: '4-week-campaign-12-day-program',
    numberOfWeeks: 4,
    daysOfFood: 12,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Balance',
    productHandle: '4-week-campaign-20-day-program',
    numberOfWeeks: 4,
    daysOfFood: 20,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Balance',
    productHandle: '4-week-campaign-11-day-program',
    numberOfWeeks: 4,
    daysOfFood: 11,
    daysOfFoodPerWeek: 3,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Balance',
    productHandle: '4-week-campaign-17-day-program',
    numberOfWeeks: 4,
    daysOfFood: 17,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Burn',
    productHandle: '3-week-campaign-15-day-program',
    numberOfWeeks: 3,
    daysOfFood: 15,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Burn',
    productHandle: '3-week-campaign-12-day-program',
    numberOfWeeks: 3,
    daysOfFood: 12,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NYLA',
  },
  {
    programName: 'Burn',
    productHandle: '3-week-campaign-15-day-program',
    numberOfWeeks: 3,
    daysOfFood: 15,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
  {
    programName: 'Burn',
    productHandle: '3-week-campaign-12-day-program',
    numberOfWeeks: 3,
    daysOfFood: 12,
    daysOfFoodPerWeek: 5,
    kitchenSuffix: 'NATIONAL',
  },
]
