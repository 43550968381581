import { useState, useEffect } from 'react'
import { customerTierLabels, defaultTag } from '@/constants/rewards'
import { $customer } from '@/stores/account/stores'
import { useStore } from '@nanostores/react'
import config from 'tailwind.config'
import './CustomerPoints.scss'
import { captureException } from '@/utilities/logger-fe'

const { screens } = config.theme

const rewardsTier: Record<string, string> = {
  SILVER: customerTierLabels.SILVER,
  GOLD: customerTierLabels.GOLD,
  PLATINUM: customerTierLabels.PLATINUM,
}

const nextRewardsTier: Record<string, string> = {
  [defaultTag]: customerTierLabels.SILVER,
  SILVER: customerTierLabels.GOLD,
  GOLD: customerTierLabels.PLATINUM,
}

const CustomerPoints = () => {
  const [customerDetails, setCustomerDetails] = useState({
    points_balance: 0,
    vip_tier_name: defaultTag,
  })
  const [nextTierSpend, setNextTierSpend] = useState(0)
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const customer = useStore($customer)

  useEffect(() => {
    const mediaCondition = window.matchMedia(`(max-width: ${screens.mdMax})`)
    setIsMobile(mediaCondition.matches)
    mediaCondition.onchange = (event) => {
      setIsMobile(event.matches)
    }

    const fetchYotpoLoyaltyData = async () => {
      try {
        const response = await fetch('/api/yotpo-loyalty', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: customer?.email }),
        })
        const data = await response.json()

        setCustomerDetails({ ...customerDetails, ...data })

        if (!data?.vip_tier_upgrade_requirements) return

        const nextTierSpend = Math.round(
          data.vip_tier_upgrade_requirements.amount_cents_needed * 0.01,
        )
        const currentSpend = data.total_spend_cents * 0.01

        setNextTierSpend(nextTierSpend)

        if (
          rewardsTier[customerDetails.vip_tier_name] !==
          customerTierLabels.PLATINUM
        ) {
          setProgressPercentage(
            (currentSpend / (currentSpend + nextTierSpend)) * 100,
          )
        } else {
          setProgressPercentage(100)
        }
      } catch (error) {
        captureException(error)
      }
    }

    fetchYotpoLoyaltyData()
  }, [])

  return (
    <div className="c-customer-points">
      {!isMobile && (
        <div className="c-customer-points__status type-h6 isDesktop">
          <a className="c-customer-points__link" href="/pages/rewards">
            {rewardsTier[customerDetails.vip_tier_name]} Status
          </a>
        </div>
      )}
      <div className="c-customer-points__progress-bar">
        <span
          style={{ width: `${progressPercentage}%` }}
          className="c-customer-points__progress"
        />
      </div>
      <div className="c-customer-points__points-container">
        {!isMobile ? (
          <div className="c-customer-points__points type-label isDesktop">
            <a className="c-customer-points__link" href="/pages/rewards">
              {customerDetails.points_balance} Points
            </a>
          </div>
        ) : (
          <div className="c-customer-points__status type-h6 isMobile">
            <a className="c-customer-points__link" href="/pages/rewards">
              {rewardsTier[customerDetails.vip_tier_name]} Status
            </a>
          </div>
        )}
        {rewardsTier[customerDetails.vip_tier_name] !==
          customerTierLabels.PLATINUM && (
          <div className="c-customer-points__next-level type-label">
            <a className="c-customer-points__link" href="/pages/rewards">
              ${nextTierSpend} to{' '}
              {nextRewardsTier[customerDetails.vip_tier_name]}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomerPoints
